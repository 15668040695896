import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import Author from "../components/Author/Author"
import PostNavigation from "../components/PostNavigation/PostNavigation"
import SocialBlock from "../components/SocialBlock/SocialBlock"
import MetaPost from "../components/MetaPost/MetaPost"
import PostContent from "../components/PostContent/PostContent"
import Newsletter from "../components/Form/Newsletter"
import "./BlogPost.scss"

export default function BlogPost({ data, pageContext, location }) {
    const { allMarkdownRemark, post } = data
    const { author, prev, next } = pageContext
    const blogAuthor = allMarkdownRemark?.nodes?.find(node => {
        return (
            node?.frontmatter?.authorName &&
            node?.frontmatter?.authorName === author?.nodes[0]?.name
        )
    })||{}
    const context = {
        pageName: "SD | Blogs",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <section className="py-2 lg:py-0">
                <div className="container-blog-content mt-10">
                    <div className="mx-auto mb-14">
                        <MetaPost blogAuthor={blogAuthor} date={post?.date} />
                        
                        {post?.content && (
                            <PostContent content={post.content} title={post.title}/>
                        )}
                    </div>
                    <Newsletter context={context}/>
                </div>
            </section>
            <section>
                <div className="blog-post-container container">
                    <div className="mx-auto max-w-[740px]">
                        <div className="social-icon grid grid-cols-1 sm:my-4">
                            <SocialBlock />
                            {(next || prev) && <PostNavigation next={next} prev={prev} />}
                        </div>
                        <Author blogAuthor={blogAuthor} />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export const pageQuery = graphql`
    query ($id: String!) {
        post: wpPost(id: { eq: $id }) {
            title
            id
            slug
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    sourceUrl
                    altText
                }
            }
            content
            date(formatString: "MMMM DD, YYYY")
            seo {
                seoTitle
                seoDescription
            }
        }

        allMarkdownRemark {
            nodes {
                frontmatter {
                    title
                    authorName
                    avatar {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    avatarAlt {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                html
            }
        }
    }
`
export const Head = ({ data }) => {
    const { seoTitle, seoDescription } = data?.post?.seo
    const { featuredImage } = data?.post
    const {title} = data?.post
    return (
        <SEO
            title={seoTitle || title + " | Saigon Digital"}
            description={seoDescription}
            image={featuredImage?.node?.sourceUrl}
        />
    )
}
